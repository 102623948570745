import React from "react";
import {
  cancelOrder,
  createOrder,
  deliverOrder,
  getClientDetail,
  getOrderTicket,
  payOrder,
  restoreSingleOrder,
  retryOrder,
  updatePrintStatus,
} from "./panel.service";
import { showError, showSuccess } from "./shared/alert.service";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// Prepare body and set actions for single orders
export const setSingleOrderAction = async (
  orderId,
  storeId,
  setIsLoading,
  updateTable,
  tabSelected,
  action
) => {
  setIsLoading(true);
  let ordersArr = {
    orderIds: [orderId],
  };
  let result = {};
  try {
    if (action === "fulfill") {
      result = await createOrder(ordersArr, storeId);
    }
    if (action === "collect") {
      result = await deliverOrder(ordersArr, storeId);
    }
    if (action === "cancel") {
      result = await cancelOrder(ordersArr, storeId);
    }
    if (action === "retry") {
      result = await retryOrder(ordersArr, storeId);
    }
    if (action === "restore") {
      result = await restoreSingleOrder(ordersArr, storeId);
    }
    if (result && result?.success) {
      showSuccess(result?.message);
    }
    if (!result?.success) {
      showError(result?.message);
    }
  } catch (error) {
    showError(error);
  } finally {
    setTimeout(() => {
      updateTable(storeId, "all");
    }, 500);
  }
  setIsLoading(false);
};

// Prepare body and set actions for multiple orders
export const setMassiveOrdersActions = async (
  ordersSelected,
  storeId,
  tabSelected,
  setIsLoading,
  updateTable,
  resetOrdersSelections,
  action
) => {
  setIsLoading(true);
  let ordersArr = {
    orderIds: ordersSelected,
  };
  let result = {};
  try {
    if (action === "fulfill") {
      result = await createOrder(ordersArr, storeId);
    }
    if (action === "collect") {
      result = await deliverOrder(ordersArr, storeId);
    }
    if (action === "cancel") {
      result = await cancelOrder(ordersArr, storeId);
    }
    if (action === "retry") {
      result = await retryOrder(ordersArr, storeId);
    }
    if (result && !result?.success) {
      showError(`${result?.message}`);
    }
    if (result && result.success) {
      showSuccess(`${result?.message}`);
    }
  } catch (error) {
    showError(error);
  } finally {
    setTimeout(() => {
      updateTable(storeId, "all");
    }, 500);
  }
  setIsLoading(false);
  resetOrdersSelections();
};

// --- Payment methhod integration ---
const createPaymentBtn = (
  preferenceId,
  country,
  classStyles,
  originalButton
) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.mercadopago.com.${country}/integrations/v1/web-payment-checkout.js`;
  script.setAttribute("data-preference-id", preferenceId);
  return script;
};

const setSingleOrderPayBtn = (
  id,
  preferenceId,
  country,
  clearOriginalSelection
) => {
  const buttonsContainer = document.querySelector(`.grid-width-sm-md-${id}`);
  const originalButton = buttonsContainer.firstChild;
  const newBtn = createPaymentBtn(
    preferenceId,
    country,
    "badge panel-format pay-deliver",
    originalButton
  );
  buttonsContainer.appendChild(newBtn);
  setTimeout(() => {
    const mpBtn = buttonsContainer.querySelector(".mercadopago-button");
    mpBtn.click();
    clearOriginalSelection();
  }, 1000);
};

const setMassivePayBtn = (preferenceId, country, clearOriginalSelection) => {
  const massiveBtnsContainer = document.querySelector(".massive-btn-wrapper");
  const newBtn = createPaymentBtn(
    preferenceId,
    country,
    "grid-fixed-size full",
    null
  );
  massiveBtnsContainer.appendChild(newBtn);
  setTimeout(() => {
    const mpBtn = massiveBtnsContainer.querySelector(".mercadopago-button");
    mpBtn.click();
    clearOriginalSelection();
  }, 1000);
};

export const getPreferenceId = async (
  rowIds,
  storeId,
  setisPreferenceId,
  clearOriginalSelection,
  existingPreferenceId = ""
) => {
  let ordersArr = {};
  if (typeof rowIds === "string") {
    ordersArr = {
      orderIds: [rowIds],
    };
  } else {
    ordersArr = {
      orderIds: [...rowIds],
    };
  }
  let preferenceId = "";
  let country = "";

  try {
    const response = await payOrder(ordersArr, storeId);
    if (response && response.success) {
      preferenceId = response.data.preferenceId;
      country = response.data.country.toLowerCase();
    }
  } catch (error) {
    showError(error);
    return;
  }

  if (ordersArr.orderIds.length !== 0 && ordersArr.orderIds.length > 1) {
    setisPreferenceId(true);
    setMassivePayBtn(preferenceId, country, clearOriginalSelection);
  } else {
    setisPreferenceId(true);
    setSingleOrderPayBtn(rowIds, preferenceId, country, clearOriginalSelection);
  }
};

//massive ticket print
async function getInfoFromBase64(base64) {
  const meta = base64.split(",")[0];
  const rawBase64 = base64.split(",")[0].replace(/\s/g, "");
  const mime = /:([^;]+);/.exec(meta);
  const extension = /\/([^;]+);/.exec(meta);

  return {
    mime,
    extension,
    meta,
    rawBase64,
  };
}

async function convertBase64ToBlob(base64) {
  const info = await getInfoFromBase64(base64);
  const sliceSize = 512;
  const byteCharacters = window.atob(info.rawBase64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}

export const printTickets = async (
  ordersToPrint,
  setIsLoading,
  storeId,
  clearOriginalSelection
) => {
  let ordersArr = {};
  if (typeof ordersToPrint === "string") {
    ordersArr = {
      orderIds: [ordersToPrint],
    };
  } else {
    ordersArr = {
      orderIds: [...ordersToPrint],
    };
  }
  setIsLoading(true);
  try {
    const result = await getOrderTicket(ordersArr, storeId);
    if (result) {
      const deliberyLabel = await convertBase64ToBlob(result.base64PDF);
      const blobUrl = URL.createObjectURL(deliberyLabel);
      window.open(blobUrl, "_blank");
    }
    setIsLoading(false);
    clearOriginalSelection();
  } catch (error) {
    showError(error);
    setIsLoading(false);
    clearOriginalSelection();
  }
};

// Set info for client modal
export const setClientInfo = async (
  orderId,
  storeId,
  ecommerce,
  setShowDetailsModal,
  setDetailsModalProps,
  tabSelected,
  updateTable
) => {
  setShowDetailsModal(false);
  const result = await getClientDetail(orderId, storeId, ecommerce);
  if (!result.success) {
    showError(result.message);
  } else {
    setDetailsModalProps({
      ecommerce: ecommerce,
      orderId: orderId,
      storeId: storeId,
      tabSelected: tabSelected,
      updateTable: updateTable,
      handleClose: () => setShowDetailsModal(false),
      orderDetail: result.orderInfo,
    });
    setShowDetailsModal(true);
  }
};

// --- pagination ---
const customTotal = (from, to, size) => (
  <span className="pagination-total-msg">Mostrar</span>
);

export const options = {
  prePageText: "<",
  nextPageText: ">",
  disablePageTitle: true,
  showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "40",
      value: 40,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};

// --- formatters ---
export const statusFormatter = (cell, row) => {
  if (row.overallTab === "pending") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{row.message}</Tooltip>}
      >
        <span className="pending-status-badge" style={{ cursor: "default" }}>
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "created") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{row.message}</Tooltip>}
      >
        <span className="created-status-badge" style={{ cursor: "default" }}>
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "delivered") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{row.message}</Tooltip>}
      >
        <span className="delivered-status-badge" style={{ cursor: "default" }}>
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
  if (row.overallTab === "issue") {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{row.message}</Tooltip>}
      >
        <span className="issue-status-badge" style={{ cursor: "default" }}>
          {row.status}
        </span>
      </OverlayTrigger>
    );
  }
};

export const guideFormatter = (cell, row) => {
  if (cell) {
    let tooltipTxt =
      window.innerWidth < 1025 ? `Seguir envío ${cell}` : "Seguir envío";
    let cellTxt = window.innerWidth < 1025 ? `${cell.slice(0, 11)}...` : cell;
    if (row.trackingUrl) {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip>{tooltipTxt}</Tooltip>}
        >
          <a
            href={row.trackingUrl}
            target="_blank"
            className="btn-link tracking-grid"
          >
            {cellTxt}
          </a>
        </OverlayTrigger>
      );
    } else {
      return <span>{cellTxt}</span>;
    }
  } else {
    return <span>-</span>;
  }
};

export const dateFormatter = (cell, row) => {
  const orderDate = new Date(row.date);
  return (
    <span>{`${orderDate.getDate()}/${
      orderDate.getMonth() + 1
    }/${orderDate.getFullYear()}`}</span>
  );
};

// --- date filter ---
export const handleDateChange = async (dates, setStartDate, setEndDate) => {
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
};

export const filterData = (data, startDate, endDate, setFilteredByDateList) => {
  if (startDate && endDate) {
    let filtered = data.filter((order) => {
      let date = new Date(order.creationDate);
      let afterEndDate = new Date(endDate.toString());
      afterEndDate.setDate(afterEndDate.getDate() + 1);
      return date >= new Date(startDate.toString()) && date < afterEndDate;
    });
    setFilteredByDateList(filtered);
  } else {
    showError("Por favor selecciona una fecha final para el filtro.");
    return;
  }
};

// --- status filter ---
export const statusArgumentArray = [
  "Pendiente",
  "Pago pendiente",
  "Pago aprobado",
  "Pago rechazado",
  "Etiqueta creada",
  "Todos",
];

export const filterByStatus = (dataArr, statusSelected) => {
  return dataArr.filter((obj) => obj.status === statusSelected);
};

// --- columns structure ---
export const columns = (
  ecommerce,
  actionsFormatter,
  singleActionsFormatter,
  statusFilterFormatter
) => {
  return [
    {
      dataField: "actions",
      text: "ACCIONES",
      formatter: actionsFormatter,
      classes: () => {
        return "grid-width-sm";
      },
      headerClasses: () => {
        return "grid-width-sm";
      },
    },
    {
      dataField: "ecommerceNumber",
      text: `# ORDEN ${ecommerce.toUpperCase()}`,
      headerClasses: () => {
        return "grid-width-sm-md";
      },
    },
    {
      dataField: "guideNumber",
      text: "NÚMERO DE SEGUIMIENTO",
      formatter: guideFormatter,
      headerClasses: () => {
        return "grid-width-lg";
      },
    },
    {
      dataField: "status",
      text: "STATUS PICKIT",
      formatter: statusFormatter,
      classes: () => {
        return "grid-width-sm-md";
      },
      headerClasses: () => {
        return "grid-width-sm-md";
      },
      headerFormatter: statusFilterFormatter,
    },
    {
      dataField: "mode",
      text: "MODO",
      classes: () => {
        return "grid-width-sm";
      },
      headerClasses: () => {
        return "grid-width-sm";
      },
    },
    {
      dataField: "date",
      text: "FECHA",
      formatter: dateFormatter,
      sort: true,
      classes: () => {
        return "grid-width-sm";
      },
      headerClasses: () => {
        return "grid-width-sm";
      },
    },
    {
      dataField: "singleActions",
      text: "ACCIÓN",
      formatter: singleActionsFormatter,
      classes: (cell, row, rowIndex, colIndex) => {
        return `grid-width-sm-md-${row.orderId}`;
      },
      headerClasses: () => {
        return "grid-width-sm-md";
      },
    },
  ];
};

export const noSelection = {
  mode: "checkbox",
  hideSelectColumn: true,
};

export const onRowSelect = (
  row,
  isSelected,
  ordersSelected,
  setOrdersSelected,
  ordersForFulfillment,
  setOrdersForFulfillment,
  ordersForPayment,
  setOrdersForPayment,
  ordersForCollect,
  setOrdersForCollect,
  typeOfMerchant
) => {
  if (
    row.originalStatus === "pending" &&
    (typeOfMerchant === "postpay-collect" ||
      typeOfMerchant === "postpay-dropoff")
  ) {
    let order = Array.from(ordersForFulfillment);
    if (isSelected) {
      order.push(row.orderId);
    } else {
      const index = order.indexOf(row.orderId);
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForFulfillment(order);
  }

  if (
    (row.originalStatus === "pending" &&
      (typeOfMerchant === "prepay-collect" ||
        typeOfMerchant === "prepay-dropoff")) ||
    row.originalStatus === "pendingPayment" ||
    row.originalStatus === "paymentRejected"
  ) {
    let order = Array.from(ordersForPayment);
    if (isSelected) {
      order.push(row.orderId);
    } else {
      const index = order.indexOf(row.orderId);
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForPayment(order);
  }

  if (
    row.originalStatus === "label created" &&
    (typeOfMerchant === "postpay-collect" ||
      typeOfMerchant === "prepay-collect")
  ) {
    let order = Array.from(ordersForCollect);
    if (isSelected) {
      order.push(row.orderId);
    } else {
      const index = order.indexOf(row.orderId);
      if (index > -1) {
        order.splice(index, 1);
      }
    }
    setOrdersForCollect(order);
  }

  let order = Array.from(ordersSelected);
  if (isSelected) {
    order.push(row.orderId);
  } else {
    const index = order.indexOf(row.orderId);
    if (index > -1) {
      order.splice(index, 1);
    }
  }
  setOrdersSelected(order);
};

export const onSelectAll = (
  isSelected,
  rows,
  setOrdersSelected,
  setOrdersForFulfillment,
  setOrdersForPayment,
  setOrdersForCollect,
  typeOfMerchant
) => {
  let fulfillOrder = [];
  let payOrder = [];
  let collectOrder = [];
  let order = [];

  if (isSelected) {
    for (let i = 0; i < rows.length; i++) {
      if (
        rows[i].originalStatus === "pending" &&
        (typeOfMerchant === "postpay-collect" ||
          typeOfMerchant === "postpay-dropoff")
      ) {
        fulfillOrder.push(rows[i].orderId);
      }
      if (
        (rows[i].originalStatus === "pending" &&
          (typeOfMerchant === "prepay-collect" ||
            typeOfMerchant === "prepay-dropoff")) ||
        rows[i].originalStatus === "pendingPayment" ||
        rows[i].originalStatus === "paymentRejected"
      ) {
        payOrder.push(rows[i].orderId);
      }
      if (
        rows[i].originalStatus === "label created" &&
        (typeOfMerchant === "postpay-collect" ||
          typeOfMerchant === "prepay-collect")
      ) {
        collectOrder.push(rows[i].orderId);
      }
      order.push(rows[i].orderId);
    }
  }

  if (!isSelected) {
    order = [];
    fulfillOrder = [];
    payOrder = [];
    collectOrder = [];
  }
  setOrdersSelected(order);
  setOrdersForFulfillment(fulfillOrder);
  setOrdersForPayment(payOrder);
  setOrdersForCollect(collectOrder);
  return order;
};

const conditionsByButton = {
  payment: {
    merchantType: ["prepay-collect", "prepay-dropoff", "prepay-both"],
  },
  collect: {
    merchantType: ["postpay-both", "prepay-both"],
  },
  fulfill: {
    merchantType: [
      "postpay-dropoff",
      "prepay-dropoff",
      "postpay-both",
      "prepay-both",
      "postpay-collect",
      "prepay-collect",
    ],
  },
};

export const validateForSingleAction = (btnAction, sample) => {
  return conditionsByButton[btnAction].merchantType.includes(sample);
};
